type queryObjType = {
  creditValue?: number;
  creditPeriod?: number;
  creditPurpose?: number;
  secureValue?: number;
  creditClientAge?: number;
  propertySort?: number;
  fixedInterestRate?: number;
  variableInterestRate?: number;
  periodicalInterestRate?: number;
  eco?: number;
  bestBankOffer?: number;
  indexWibor?: number;
  indexWiron?: number;
  clientInternal?: number;
  clientExternal?: number;
};

export function formToQuery(
  creditVal: number,
  periodVal: number,
  ageVal: number
) {
  const queryObj: queryObjType = {};
  // pseudo DTO
  queryObj.creditValue = creditVal;
  queryObj.creditPeriod = periodVal;
  queryObj.creditClientAge = ageVal;

  queryObj.fixedInterestRate = 1;
  queryObj.variableInterestRate = 1;
  queryObj.periodicalInterestRate = 0;
  queryObj.eco = 0;
  queryObj.bestBankOffer = 1;
  queryObj.indexWibor = 1;
  queryObj.indexWiron = 1;
  queryObj.clientInternal = 1;
  queryObj.clientExternal = 1;

  return queryObj;
}
